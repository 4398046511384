'use client';

import PageViewTracker from '@withlocals/analytics/PageViewTracker';

import { useSession } from '../../auth/useSession';

const PageViewTrackerWithSession = () => {
  const { data: userData, status } = useSession();

  return (
    <PageViewTracker
      userEmail={userData?.user.email}
      userId={userData?.user?.id}
      sessionStatus={status}
    />
  );
};

export default PageViewTrackerWithSession;
