"use client";
import { usePathname, useSearchParams } from "next/navigation";
import { useEffect } from "react";
import { eventBus } from "./EventBus";
function PageViewTracker(_a) {
    var userId = _a.userId, userEmail = _a.userEmail, sessionStatus = _a.sessionStatus;
    var pathname = usePathname();
    var searchParams = useSearchParams();
    // Check for navigation events as per Vercel's own recommendation
    // https://nextjs.org/docs/app/api-reference/functions/use-router#router-events
    useEffect(function () {
        // To avoid duplicate page view events, we only send the event if the session is not loading
        if (sessionStatus === "loading")
            return;
        var pageLocation = searchParams
            ? "".concat(window.location.origin).concat(pathname, "?").concat(searchParams)
            : "".concat(window.location.origin).concat(pathname);
        var params = { page_location: pageLocation };
        if (userId)
            params["_userId"] = userId;
        if (userEmail)
            params["userEmail"] = userEmail;
        eventBus.dispatch({
            name: "page_view",
            params: params,
        });
    }, [userId, userEmail, sessionStatus, pathname, searchParams]);
    return null;
}
export default PageViewTracker;
